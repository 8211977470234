import React, {useEffect, useState} from 'react';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import { Rating, ThinStar } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import PopupCasino from './PopupCasino';
import Modal from 'react-bootstrap/Modal';
import { transformEntryFields } from '../../../utils/tokenReplace';

const CasinoList = ({load, showMore}) => {
  const [allCasinos, setAllCasinos] = useState([]);
  const [next, setNext] = useState(load);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = (id) => setModalShow(id);
  const handleMore = () => {
    setNext(next + load);
  };
    
  const customStyles = {
    itemShapes: ThinStar,
    activeFillColor: '#FFC93A',
    inactiveFillColor: '#A5A2B2',
  };
  const [rating, setRating] = useState(0);

  const client = createClient({
    space: 'qzvdhv6dk1nu',
    environment: 'master',
    accessToken: 'yF08NDjz2PAvDXY7SsJ57o2vkwpxT1zOjunvfbNOx_4'
  })

  useEffect(() => {
    const getAllCasinos = async () => {
      try {
        const entries = await client.getEntries({
          content_type: 'casino',
          order: 'fields.orderPriority,-fields.rating',
        });

        // 2) Recursively transform each entry so placeholders are replaced
        entries.items.forEach((item) => {
          transformEntryFields(item);
        });

        setAllCasinos(entries);
        // console.log(entries)
      } catch (error) {
        console.log(error);
      }
    };
    getAllCasinos();
  }, []);

  if (!allCasinos) {
    return "Loading...";
  }

  return (
    <div className="container pt-0">
      {allCasinos?.items?.slice(0, next)?.map((casino, index) => {
        return (
          <div className="single-entry border border-1 border-ash mb-3" key={casino.sys.id}>

            <div className="single-casino d-flex flex-wrap">

              <div className="col-12 col-lg-3 col-xl-2 column-01 position-relative h-75px h-lg-auto">
                <div className="index position-absolute top-0 start-0 mt-1 ms-1 bg-black text-white w-25px h-25px d-inline-flex justify-content-center align-items-center fw-medium rounded-1">{index + 1}</div>
                <div className={casino.fields?.logoBackground ? "d-flex flex-wrap justify-content-between justify-content-lg-center align-items-center p-2 h-100" : "d-flex flex-wrap justify-content-between justify-content-lg-center align-items-center bg-black bg-lg-ash p-2"} 
                style={casino.fields?.logoBackground ? {background: '#' + casino.fields?.logoBackground} : null}>
                  <div className="img-wrap d-flex col-3 col-lg-12 justify-content-center order-2 order-lg-1 mt-lg-4">
                    <img src={casino.fields.featuredImage.fields.file.url + '?h=50'} width={'auto'} height={50} alt={casino.fields.title}></img>
                  </div>
                  <div className="d-flex col-3 col-lg-5 mt-lg-1 me-lg-1 fs-9 fs-md-8 fw-bold align-items-center justify-content-start order-1 order-lg-2 align-self-end">
                    <div className={casino.fields.von ? 'bg-secondary py-1 px-2 text-center lh-lg rounded-1' : ''}>{casino.fields.von ? 'von ' + casino.fields.von : ''}
                    </div>
                  </div>
                  <div className="d-flex col-3 col-lg-5 mt-lg-1 order-3 ms-lg-1 payment-methods align-self-end">
                    <div className={casino.fields?.featuredPaymentMethod ? casino.fields?.featuredPaymentMethod + ' pay-method payment-field d-flex w-100 fs-9 fs-lg-8 ps-2 fw-bold text-uppercase justify-content-end' : 'pay-method payment-field d-flex w-100 fs-9 fs-lg-8 ps-2 fw-bold text-uppercase justify-content-end'}>
                      <span className={casino.fields?.featuredPaymentMethod ? casino.fields?.featuredPaymentMethod + ' pay-logo' : 'pay-logo'}></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-7 col-xl-8 d-grid casino-grid d-lg-flex flex-column flex-lg-row bg-light px-2 py-3 p-lg-0 min-h-185px min-h-lg-110px">
                <div className="d-col-1 col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center bg-white">
                  <div className="d-flex align-items-center"><span className="fs-1 fs-md-2 fw-bold lh-base">{casino.fields?.rating ? casino.fields.rating : ''}</span><span className="num-of fs-3 lh-base text-black-50 fw-semibold">/5</span></div>
                  <Rating
                    style={{ maxWidth: 86 }}
                    value={casino.fields.rating}
                    itemStyles={customStyles}
                    readOnly
                  />
                </div>

                <div className="d-col-2 col-12 col-lg-2 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                  <div className="fs-6 ps-3 ps-lg-0">Bonus</div>
                  <div className="display-3 ps-3 ps-lg-0 fs-lg-5 fw-bold text-green">{casino.fields.bonus ? casino.fields.bonus + '€' : casino.fields.bonus}</div>
                </div>

                <div className="d-col-3 col-12 col-lg-2 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                  <div className="fs-8 fs-md-6 ps-3 ps-lg-0 ">RTP</div>
                  <div className="fs-7 fs-md-5 fw-bold ps-3 ps-lg-0 ">{casino.fields.rtp ? casino.fields.rtp + '%' : casino.fields.rtp}</div>
                </div>

                <div className="d-col-4 col-12 col-lg-3 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                  <div className="fs-8 fs-md-6 ps-2 ps-lg-0">Auszahlung</div>
                  <div className="fs-7 fs-md-5 ps-2 ps-lg-0 fw-bold">{casino.fields.auszahlungen ? casino.fields.auszahlungen : '-'}</div>
                </div>

                <div className="d-none col-12 col-lg-2 d-lg-flex flex-column justify-content-center align-items-center bg-light">
                  <div className="fs-6">Freispiele</div>
                  <div className="fs-5 fw-bold">{casino.fields.freispiele}</div>
                </div>
              </div>

              <div className="col-12 col-lg-2 d-grid gtc-2-3 d-gap-30 d-gap-lg-init d-lg-flex justify-content-between flex-lg-column-reverse justify-content-lg-center align-items-center bg-light px-2 px-lg-0 pb-3 pb-lg-0">
                <Link
                to={`/casinos/${casino.fields.slug}`}
                // id={casino.sys.id}
                className="btn btn-outline-dark w-sm-140px text-center fs-6 fw-bold p-2 text-decoration-none justify-self-center">Testbericht</Link>
                {/* {casino.fields.jetztSpielenShow ? 
                <a href={casino.fields.externalLinkToCasino} target="_blank" className="btn btn-secondary w-lg-140px text-center fs-6 fw-bold p-2 text-dark text-decoration-none mb-lg-3">Jetzt spielen</a>
                : null } */}

                { casino?.fields?.popupTitle ? 
                  <>
                    <Modal 
                    show={modalShow === casino.sys.id} 
                    onHide={handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    key={casino.sys.id}
                    >
                      <Modal.Header closeButton className="bg-secondary border-0">
                        <Modal.Title id="contained-modal-title-vcenter">
                          {casino?.fields?.popupTitle}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-0">
                        <PopupCasino 
                          pl={casino?.fields?.popupLink ? casino?.fields?.popupLink : ''} 
                          plp={casino?.fields?.popupLinkProminent ? casino?.fields?.popupLinkProminent : ''} 
                          popupCasino={casino?.fields?.popupCasino ? casino?.fields?.popupCasino : ''} 
                          externalLink={casino?.fields?.externalLinkToCasino ? casino?.fields?.externalLinkToCasino : ''}></PopupCasino>
                      </Modal.Body>
                    </Modal>
                    <button className="btn btn-secondary w-lg-140px text-center fs-6 fw-bold p-2 text-dark text-decoration-none mb-lg-3" type="button" onClick={() => handleShow(casino.sys.id)}>Jetzt Spielen</button>
                  </>
                :
                  <>
                    { casino?.fields?.externalLinkToCasino ?
                    <a href={casino?.fields?.externalLinkToCasino} className="btn btn-secondary w-lg-140px text-center fs-6 fw-bold p-2 text-dark text-decoration-none mb-lg-3" target="_blank" rel={casino?.fields.jetztSpielenShow ? '' : 'nofollow'} >Jetzt Spielen</a>
                    : null }
                  </>
                }
              </div>

            </div>
            {casino.fields?.casinoDisclaimer ?
              <div className="single-disclaimer px-2 py-1 fs-8 bg-mild-green">{casino.fields?.casinoDisclaimer}</div>
            : null }

          </div>
        );
      })}
      {next < allCasinos?.items?.length && (
        <div className="container d-flex justify-content-center">
          {showMore === true ?
          <button
            className="btn btn-outline-secondary rounded-0 mt-3 text-dark fw-bold py-2 px-4"
            onClick={handleMore}
          >
            mehr anzeigen
          </button>
          : null }
          </div>
        )}
    </div>
  )
}

export default CasinoList;