import React, {useEffect, useState} from 'react';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import person from '../../../assets/img/person.png';
import { transformEntryFields } from '../../../utils/tokenReplace';

const Authors = ({w, h, minh}) => {

  const [authors, setAuthors] = useState([]);

  const client = createClient({
    space: 'qzvdhv6dk1nu',
    environment: 'master',
    accessToken: 'yF08NDjz2PAvDXY7SsJ57o2vkwpxT1zOjunvfbNOx_4'
  })

  useEffect(() => {
    const getAuthors = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "author"
        });

        // 2) Transform each item to replace tokens in nested fields
        entries.items.forEach((item) => {
          transformEntryFields(item);
        });

        // 3) Store the fully transformed data in state
        setAuthors(entries);
      } catch (error) {
        console.log(error);
      }
    };
    getAuthors();
  }, [client]);

  if (!authors) {
    return "Loading...";
  }

  return (
    <div className="container pt-1">
      {authors?.items?.map((author) =>
      <Link 
      to={`/autor/${author.fields.slug}`}
      id={author.sys.id}
      className={minh ? 'min-h-' + minh + 'px single-entry single-author d-flex flex-wrap mb-2 mb-md-3 align-items-center' : 'min-h-85px single-entry single-author d-flex flex-wrap mb-2 mb-md-3 align-items-center'} key={author.sys.id}>

        <div className="img-wrap d-flex col-4 col-sm-3 col-md-2 col-lg-1 me-3">
          <img src={author?.fields?.avatar ? author?.fields?.avatar?.fields?.file?.url + '?h=100' : person} 
               className="rounded-100"
               width={w ? w : 100}
               height={h ? h : 100}
               alt={author?.fields?.name}></img>
        </div>

        <div className="col-4 col-sm-8 col-md-6 col-lg-5">
          <h4 className="mb-0">{author?.fields?.name}</h4>
        </div>

      </Link>
      )}

    </div>
  )
}

export default Authors
