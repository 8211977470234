import Logo from './../../assets/img/CV-logo.svg';
import f01 from './../../assets/img/18plus.png';
import f02 from './../../assets/img/bzga.png';
import f03 from './../../assets/img/spielnichtbiszursucht.png';

const Footer= () => {

  return (
  <footer className="footerclass bg-mild-green py-5 mt-5">
    <div className="container py-5">
      <div className="logo d-flex justify-content-center"><img src={Logo} alt="Casinos Vergleich Logo" width="55" height="53" ></img></div>
      <div className="footer-nav flex-column flex-sm-row d-flex align-items-center justify-content-sm-center mt-3">
        <a href="/impressum" className="text-decoration-none fw-bold text-dark">Impressum</a>
        <span className="d-none d-sm-inline-flex fw-bold mx-1">|</span>
        <a href="/datenschutz" className="text-decoration-none fw-bold text-dark">Datenschutz</a>
        <span className="d-none d-sm-inline-flex fw-bold mx-1">|</span>
        <a href="/transparenz-hinweis" className="text-decoration-none fw-bold text-dark">Transparenz-Hinweis</a>
      </div>
      <div className="footer-dsc d-flex align-items-center justify-content-center mt-3 flex-wrap">
        <a href="https://www.check-dein-spiel.de/" target='_blank' rel="noopener noreferrer">
          <img src={f02} alt="Die Bundeszentrale für gesundheitliche Aufklärung Logo Logo" width="95" height="77" className="me-3"></img>
        </a>
        <img src={f01} alt="18+" width="65" height="73" className="me-3"></img>
        <a href="https://www.check-dein-spiel.de/" target='_blank' rel="noopener noreferrer">
          <img src={f03} alt="Spiel nicht bis zur Glücksspielsucht Logo" width="80" height="70"></img>
        </a>
      </div>
      <a 
      href="https://www.bundesweit-gegen-gluecksspielsucht.de/" 
      target='_blank' 
      rel="noopener noreferrer" 
      className="d-block fs-7 text-center mt-3">
      Bundesweit gegen Glücksspielsucht
      </a>
      <div className="footer-dsc-text fs-8 text-center mt-3">
      <a href="https://casinos-vergleich.de/">Casinos-Vergleich.de</a> bietet dir einen kostenlosen Vergleich von legalen Online-Spielotheken <a href="https://www.gluecksspiel-behoerde.de/de/fuer-spielende/uebersicht-erlaubter-anbieter-whitelist" target='_blank' rel="noopener noreferrer">(lt. White-List)</a>, durchgeführt mit reichhaltiger Expertise. Casinos-Vergleich finanziert sich auch über die Nutzung von Affiliate-Links. Dadurch erhalten wir möglicherweise eine Kommission erhalten, wenn du dich über unseren Link bei einer Online-Spielothek registrierst und dort spielst. Diese Kommission hat keinerlei Einfluss auf die Bewertung der Online-Spielotheken, wir führen unsere Tests unabhängig durch. Voraussetzung für die Nutzung der Website Casinos-Vergleich.de ist die Vollendung des 18. Lebensjahres. Online Glücksspiel ist in manchen Ländern illegal. Es liegt in deiner Verantwortung, die örtlichen Vorschriften genau zu prüfen. Spiele verantwortungsbewusst. Wir übernehmen keinerlei Verantwortung für dein Handeln. Copyright © 2022–2025 <a href="https://casinos-vergleich.de/">Casinos-Vergleich.de</a>. Alle Rechte vorbehalten.
      </div>
    </div>
  </footer>
  );
}

export default Footer;