// src/utils/transform.js

export function tokenReplace(text) {
  const year = new Date().getFullYear();
  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];
  const month = monthNames[new Date().getMonth()];

  return text
    .replace(/%YEAR%/g, year)
    .replace(/%MONTH%/g, month);
}

/**
 * Recursively walks an object/array to find any string fields
 * and applies tokenReplace to them.
 */
export function transformEntryFields(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj; // Not an object/array, nothing to transform
  }

  // If it's an array, transform each item
  if (Array.isArray(obj)) {
    return obj.map((item) => transformEntryFields(item));
  }

  // Otherwise it's an object
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (typeof value === 'string') {
      obj[key] = tokenReplace(value);
    } else if (typeof value === 'object') {
      // Recurse deeper
      obj[key] = transformEntryFields(value);
    }
  }
  return obj;
}

