import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types";
import { Rating, ThinStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';
import 'react-circular-progressbar/dist/styles.css';
import { tokenReplace } from '../../../utils/tokenReplace';

const PopupCasino = ({pl, plp, popupCasino, externalLink}) => {

    const customStyles = {
      itemShapes: ThinStar,
      activeFillColor: '#FFC93A',
      inactiveFillColor: '#A5A2B2',
    };

    const renderOptions = {
      renderText: text => tokenReplace(text),
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          // Casino
          if (node?.data?.target?.sys?.contentType?.sys?.id === "casino") {
            return (
              <div className="single-entry d-flex flex-wrap">
                <div className="col-12 column-01">
                  <div className={node?.data?.target?.fields?.logoBackground ? "d-flex flex-wrap justify-content-between align-items-center p-2 h-100" : "d-flex flex-wrap justify-content-between align-items-center bg-black p-2"} 
                  style={node?.data?.target?.fields?.logoBackground ? {background: '#' + node?.data?.target?.fields?.logoBackground} : null}>
                    <div className="img-wrap d-flex col-3 justify-content-center order-2">
                      <img src={node?.data?.target?.fields?.featuredImage.fields.file.url + '?h=50'} alt={node?.data?.target?.fields?.title}></img>
                    </div>
                    <div className="d-flex col-3 me-1 fs-9 fw-bold align-items-center order-1">
                      <div className={node?.data?.target?.fields?.von ? 'bg-secondary w-100 py-1 px-2 text-center' : ''}>{node?.data?.target?.fields?.von ? 'von ' + node?.data?.target?.fields?.von : ''}
                      </div>
                    </div>
                    <div className="d-flex col-3 order-3 ms-1 payment-methods">
                      <div className={node?.data?.target?.fields?.featuredPaymentMethod ? node?.data?.target?.fields?.featuredPaymentMethod + ' pay-method payment-field d-flex w-100 fs-9 fs-lg-8 py-1 px-2 fw-bold text-uppercase justify-content-center' : 'pay-method payment-field d-flex w-100 fs-9 fs-lg-8 py-1 px-2 fw-bold text-uppercase justify-content-center'}>
                        <span className={node?.data?.target?.fields?.featuredPaymentMethod ? node?.data?.target?.fields?.featuredPaymentMethod + ' pay-logo' : 'pay-logo'}></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 d-grid casino-grid flex-column bg-light px-2 py-3">
                  <div className="d-col-1 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
                  <div className="d-flex align-items-center"><span className="fs-1 fw-bold lh-base">{node?.data?.target?.fields?.rating ? node?.data?.target?.fields?.rating : ''}</span><span className="num-of fs-3 lh-base text-black-50 fw-semibold">/5</span></div>
                    <Rating
                      style={{ maxWidth: 86 }}
                      value={node?.data?.target?.fields?.rating}
                      itemStyles={customStyles}
                      readOnly
                    />
                  </div>

                  <div className="d-col-2 col-12 d-flex flex-column justify-content-center bg-light">
                    <div className="fs-6 ps-3">Bonus</div>
                    <div className="display-3 ps-3 fw-bold text-green">{node?.data?.target?.fields?.bonus ? node?.data?.target?.fields?.bonus + '€' : node?.data?.target?.fields?.bonus}</div>
                  </div>

                  <div className="d-col-3 col-12 d-flex flex-column justify-content-center bg-light">
                    <div className="fs-8 fs-md-6 ps-3">RTP</div>
                    <div className="fs-7 fs-md-5 fw-bold ps-3">{node?.data?.target?.fields?.rtp ? node?.data?.target?.fields?.rtp + '%' : node?.data?.target?.fields?.rtp}</div>
                  </div>

                  <div className="d-col-4 col-12 d-flex flex-column justify-content-center bg-light">
                    <div className="fs-8 fs-md-6 ps-2">Auszahlung</div>
                    <div className="fs-7 fs-md-5 ps-2 fw-bold">{node?.data?.target?.fields?.auszahlungen ? node?.data?.target?.fields?.auszahlungen : '-'}</div>
                  </div>

                  <div className="d-none col-12 flex-column justify-content-center align-items-center bg-light">
                    <div className="fs-6">Freispiele</div>
                    <div className="fs-5 fw-bold">{node?.data?.target?.fields?.freispiele}</div>
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center bg-ash px-2 py-3">
                  { pl[0] ? 
                    <a href={pl[0]} className="btn rounded-0 text-decoration-underline text-dark" target="_blank" rel="nofollow">
                      {pl[1] ? pl[1] : 'Nein Danke'}
                    </a> 
                  : externalLink ? <a href={externalLink} className="btn rounded-0 text-decoration-underline text-dark" target="_blank" rel="nofollow">Nein Danke</a> : ''
                  }
                  { plp[0] ? 
                    <a href={plp[0]} className="fs-6 fw-bold py-2 px-4 text-dark btn btn-secondary text-decoration-none" target="_blank">
                      {plp[1] ? plp[1] : 'Klingt gut!'}
                    </a> 
                  : <a href={node?.data?.target?.fields?.externalLinkToCasino}
                    className="fs-6 fw-bold py-2 px-4 text-dark btn btn-secondary text-decoration-none" target="_blank">Klingt gut!</a>
                  }
                </div>

              </div>
            );
          }
        },
      },
    };

  return (
    <div className='popup-casino'>
      {documentToReactComponents(popupCasino, renderOptions)}
    </div>
  );
}

export default PopupCasino;