import React, {useEffect, useState} from 'react';
import SEO from "../Components/SEO";
import { Helmet } from 'react-helmet-async';
import { createClient } from 'contentful';
import { transformEntryFields } from '../../utils/tokenReplace';
import HeroHome from '../Components/HeroHome';
import Casinos from "../Components/Casinos/CasinoList";
import Content from '../Components/Content';
import Article from '../Components/Article/Article';
import ArticleInfo from '../Components/Article/ArticleInfo';
import Authors from '../Components/Author/Authors';
import iCheck from '../../assets/img/icons/check.svg';
import Blacklist from '../Components/Casinos/Blacklist';
import i8 from '../../assets/img/icons/roulette.svg';
import i9 from '../../assets/img/icons/blackjack.svg';
import i10 from '../../assets/img/icons/spielautomaten.svg';
import i11 from '../../assets/img/icons/live-casino.svg';


const Page = () => {

    const [page, setPage] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
  
    const client = createClient({
      space: 'qzvdhv6dk1nu',
      environment: 'master',
      accessToken: 'yF08NDjz2PAvDXY7SsJ57o2vkwpxT1zOjunvfbNOx_4'
    });
  
    useEffect (() => {
      const getPage = async () => {
        try {
          await client.getEntry('61mtQSwWme5HI2sy5XejTJ').then((entry) => {
            setPage(entry);
            setIsDataLoaded(true);
          })
        } catch(error) {
          console.log(error);
        }
  
      }
      getPage();
  
    }, [])
  
    if (!page) {
      return "Loading...";
    }

    
    const load = page?.fields?.casinoNumber ? page?.fields?.casinoNumber : 10;
    const showLoadMore = page?.fields?.showLoadMoreCasinosButton === false ? false : true;

  return (
    <div className="home">

      <SEO
      title={page.fields?.seoFields?.fields?.metaTitle ? page.fields?.seoFields?.fields?.metaTitle : page.fields?.title}
      metaTitle={page.fields?.seoFields?.fields?.metaTitle ?
      page.fields?.seoFields?.fields?.metaTitle + ' | Casinos Vergleich'
      : page.fields?.title + ' | Casinos Vergleich'}
      description={page.fields?.seoFields?.fields?.metaDescription}
      />

      <HeroHome 
      headline={page.fields?.headline}
      teaser={page.fields?.teaser}
      idx={page.fields?.slug}
      title={page.fields?.seoFields?.fields?.metaTitle ? page.fields?.seoFields?.fields?.metaTitle : page.fields?.title}
      buttons={page?.fields?.headerButtons ? page?.fields?.headerButtons : ''}
      ></HeroHome>

      <main>
      <div className="container">
        { page?.fields?.casinoListTitle ? 
        <h2 className="fw-bold mt-5 mb-5">{page?.fields?.casinoListTitle}</h2>
        : null }
      </div>
      { isDataLoaded ? 
      <Casinos 
      casinos={Casinos} 
      showMore={showLoadMore}
      load={load}></Casinos>
      : null}

      { page?.fields?.bewertungskriterien ?
      <div className="container-fluid pt-5">
        <div className="container mt-5">
          <h5 className="fw-normal">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterienSubtitle}</h5>
          <h2 className="mb-4">{page?.fields?.bewertungskriterien?.fields?.title}</h2>
          <div className="d-flex row g-4 mt-1">
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie01Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie01Text}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie02Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie02Text}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie03Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie03Text}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie04Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie04Text}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie05Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie05Text}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <div className="col-1"><img src={iCheck} width={24} height={24} alt=''></img></div>
              <div className="col-11">
                <h4>{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie06Title}</h4>
                <p className="fs-5 lh-base">{page?.fields?.bewertungskriterien?.fields?.bewertungskriterie06Text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      : null }

      { page?.fields?.blacklist ?
      <div className="container-fluid pt-5">
        <div className="container mt-5">
          <div className="d-flex row g-5 align-items-center">
            <div className="col-12 col-md-6 col-lg-4">
            <h5 className="fw-normal">Online Casino Betrug</h5>
              <h2 className="mb-4">Schwarze Liste für unseriöse Online Casinos</h2>
              <p>Sie können sich auf die von uns empfohlenen Online Casinos für Deutschland verlassen, denn sie sind lizenziert und werden ständig überprüft. Leider gibt es aber darüber hinaus noch einige andere Internet Casinos, die unseren Kriterien nicht entsprechen. Wir haben daher eine schwarze Liste erstellt mit Anbietern für Deutschland, Österreich und die Schweiz, die Sie meiden sollten.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-8">
            { isDataLoaded ? <Blacklist blacklisted={page?.fields?.blacklist}></Blacklist> : null }
            </div>
          </div>
          <a href="#" className="fw-bold btn-icon mt-3">Zur Casino Blacklist 2024</a>
        </div>
      </div>
      : null }

      <div className="container-fluid pt-5">
        <div className="container mt-5">
          <div className="d-flex row align-items-center gx-1">
            <div className="col-12 col-md-6 col-lg-4 min-h-275px">
            <h5 className="fw-normal">Dein Online Spielotheken Ratgeber</h5>
              <h2 className="mb-4">{page?.fields?.ratgeberLinksTitle}</h2>
              <p>{page?.fields?.ratgeberLinksText}</p>
            </div>
            <div className="col-12 col-md-6 col-lg-8 row gx-4">
              <div className="col-12 col-lg-6 mt-0 h-150px">
                <div className="bg-light p-4 d-flex flex-column align-items-start">
                <img src={i8} alt="" height={64} width={64}></img>
                <a href={page?.fields?.ratgeberLinks[0]} className="fw-bold btn-icon mt-3">Roulette</a>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-md-4 mt-lg-0 h-150px">
                <div className="bg-light p-4 d-flex flex-column align-items-start">
                <img src={i9} alt="" height={64} width={64}></img>
                <a href={page?.fields?.ratgeberLinks[1]} className="fw-bold btn-icon mt-3">Blackjack</a>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-md-4 h-150px">
                <div className="bg-light p-4 d-flex flex-column align-items-start">
                <img src={i10} alt="" height={64} width={64}></img>
                <a href={page?.fields?.ratgeberLinks[2]} className="fw-bold btn-icon mt-3">Spielautomaten</a>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-md-4 h-150px">
                <div className="bg-light p-4 d-flex flex-column align-items-start">
                <img src={i11} alt="" height={64} width={58}></img>
                <a href={page?.fields?.ratgeberLinks[3]} className="fw-bold btn-icon mt-3">Live Casino</a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-md-end me-md-4"><a href="/ratgeber" className="fw-bold btn-icon mt-3">alle Casino Guides</a></div>
        </div>
      </div>

      <div className="container mt-5 pt-5 gx-4">
        <div className="d-flex row align-items-center px-4 py-5 bg-mild-green">
          <div className="col-12 col-md-6 col-lg-5 min-h-285px">
          {/* <h5 className="fw-normal">Ihr Online Casino Experten</h5> */}
            <h2 className="mb-4">{page?.fields?.expertenTitle}</h2>
            <p>{page?.fields?.expertenText}</p>
            <a href="/autoren" className="fw-bold btn-icon mt-0 mb-4 mb-md-0 mt-md-3">mehr lesen</a>
          </div>
          <div className="col-12 col-md-6 col-lg-7 d-flex ps-md-5">
           <Authors authors={Authors} w={51} h={51} minh={75}></Authors>
          </div>
        </div>
        <div className="d-flex justify-content-md-end me-md-4"><a href="/ratgeber" className="fw-bold btn-icon mt-3">alle Casino Guides</a></div>
      </div>

      <div className="container mt-5 pt-5">
        <div className="d-flex row align-items-center">
          <div className="col-12">
            <h2 className="mb-4">Ratgeber</h2>
            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.</p> */}
          </div>
          <div className="col-12">
          <Article slugBase={'/ratgeber/'} contentType={'ratgeberArticle'} limit={4}/>
          </div>
          <div className="d-flex justify-content-center mt-5">
              <a href="/ratgeber" className="btn btn-outline-secondary rounded-0 mt-3 text-dark fw-bold py-2 px-4">alle Ratgeber</a>
          </div>
        </div>
      </div>

      <div className="container mt-5 pt-5">
        <div className="d-flex row align-items-center">
          <div className="col-12">
            <h2 className="mb-4">News</h2>
          </div>
          <div className="col-12">
          <Article slugBase={'/news/'} contentType={'newsArticle'} limit={4}/>
          </div>
          <div className="d-flex justify-content-center mt-5">
              <a href="/news" className="btn btn-outline-secondary rounded-0 mt-3 text-dark fw-bold py-2 px-4">alle News</a>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="ribbon bg-mild-green position-relative z-0"></div>
        <div className="container bg-light p-3 p-lg-5 position-relative z-1">
          <h2 className="fw-bold">{page?.fields?.contentTitle}</h2>

          { isDataLoaded ? 
          <ArticleInfo
          name = {page?.fields?.author?.fields?.name}
          avatar = {page?.fields?.author?.fields?.avatar?.fields?.file?.url + '?h=30'}
          slug = {'autor/' + page?.fields?.author?.fields?.slug}
          createdAt = {new Date(page?.sys?.createdAt).toLocaleDateString("en-GB")}
          updatedAt = {new Date(page?.sys?.updatedAt).toLocaleDateString("en-GB")}>
          </ArticleInfo>
          : null }

          { isDataLoaded ? <Content id={page?.sys?.id}></Content> : null }

        </div>
      </div>

      </main>

    </div>
  );
}

export default Page;